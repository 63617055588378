import React from 'react';
import StripeCheckout from 'react-stripe-checkout';
import firebase from 'firebase';
require('firebase/functions');

const StripeCheckoutButton = ({ price, email, currState, currProducts, setLoading}) => {
    
    const frontendData = {
        currState,
        currProducts,
        orderID: `${Date.now()}${Math.ceil(Math.random()*99)}`
    }

    const priceForStripe = price*100;
    const publishableKey = 'pk_live_51Hkns5LONRdAmV3Nd95abO8rQ6KEjSMeIyJOJZVwVUqw8xL7XwCMjpap5QDRgvC2YFF6SwQXpRRZfGsHlqXtaIvC00x7PXkMZm';

    const sendMail = async currState => {
        const sendMail = firebase.functions().httpsCallable('sendMailPayment');
        sendMail(currState).then(function(result) {
            if (result.data) {
                cardPayment(result.data)
            } 
            else {
                alert('Przepraszamy, coś poszło nie tak, spróbuj ponownie za chwilę')
            }
    
        })
    }

    const cardPayment = data => {
        const cardPayment = firebase.functions().httpsCallable('cardPayment');
        cardPayment(data).then(function(result) {
            if (result.data) {
                window.location.href = "https://plystation.eu/card/dziekujemy"
            } else {
                alert('Przepraszamy, coś poszło nie tak, spróbuj ponownie za chwilę. Sprawdź czy wpisałeś prawidłowe dane i czy posiadasz odpowiednią ilość środków na koncie.')
            }
        })
    }

    const onToken = token => {

        setLoading()

        const toSend = {
            currState:frontendData.currState,
            currProducts: frontendData.currProducts,
            orderID: frontendData.orderID,
            token: token.id
        }

        sendMail(toSend)
    }

    return (
        <StripeCheckout
            label="Zapłać kartą"
            name='PLY-STATION'
            description={`Kwota do zapłaty: ${price} zł`}
            amount={priceForStripe}
            panelLabel="Zapłać kartą"
            token={onToken}
            stripeKey = {publishableKey}
            currency="PLN"
            ComponentClass="div"
            email = {email}
            locale = "pl"
        >
        <button className ='last-btn'>KUPUJĘ I PŁACĘ</button>
        </StripeCheckout>
    )
}

export default StripeCheckoutButton