import React from 'react'

import './section1.style.scss'

import Desktop from '../../assets/desktop/section1_desktop.jpg'
import Mobile from '../../assets/mobile/section1_mobile.jpg'

const Section1 = ( {windowWidth, history} ) => {

const gotoShop = () => {
    history.push('sklep')
    window.scrollTo(0, 0)

}

    return (
        <section id = 'section1'>
        <div className = 'container-top'>
            <div className = 'img-container-top'>
                <img src = {windowWidth > 800 ? Desktop : Mobile} alt = 'Deska Plystation na kierownicy' />
            </div>

            <div className = 'content'>
                <h1 style ={{textAlign: 'center'}}>Auto jest twoim biurem?</h1>
                <p>
                Deska PLY STATION- to wyjątkowy produkt, który powstał z potrzeby efektywnej pracy w samochodzie. 
                Założona na kierownicę, w kilka sekund zmienia się w ergonomiczne biurko, pozwalające na wygodną pracę na laptopie i innych urządzeniach mobilnych.
                </p>
                <button onClick = {gotoShop}>Zamów swoje biurko</button>
            </div>
        </div>

    </section>
    )
  
}

export default Section1

