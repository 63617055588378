import React, {useState, useEffect} from 'react'
import Logo from '../assets/logo.svg'
import { Link } from 'react-router-dom'
import Spinner from '../components/spinner/Spinner'

import './thankYou.style.scss'


const ThankYouCard = () => {


    return(

        <div className = 'loading-start' style = {{width: "100vw", height: "100vh" ,background: 'rgba(13, 65, 55, 0.1)'}} >

               <div className = 'thankYou-page'>

                      <div className = 'middle'>
              
                      <div className = 'content-center'>
                          <h2>Dziękujemy za zakup w naszym sklepie!</h2>
                          <p>Zakasujemy rękawy i zabieramy się za produkcję Twojej deski! :)</p>
                          <p>Deski produkujemy w małym warsztacie pod Warszawą- i stąd paczka wyruszy w drogę do Ciebie. Robimy je na zamówienie, dlatego czas realizacji może wynieść do 21 dni- ale zazwyczaj staramy się wysłać je o wiele wcześniej.</p>
                          <p>Jesteśmy pewni, że z biurkiem samochodowym praca w samochodzie, będzie o niebo wygodniejsza, a jeżeli kupiłeś ją jako prezent- sprawisz obdarowanej osobie dużo radości!</p>
                          <p>Na skrzynkę e-mail dostaniesz także wiadomość z potwierdzeniem transakcji.</p>
                          <p>Pozdrawiamy,</p>
                          <p>Zespół PLY STATION</p>
                      </div>

                      <div className = 'links'>
                          <Link to="/" className = 'logo-cont'>
                              <img className = 'logo' src={Logo} alt='Plystation logo' />
                          </Link> 

                          <Link to="/" className = 'powrot'>
                              <div ><span className="fas fa-arrow-left"></span>Powrót do sklepu</div>
                          </Link> 
                      </div>
                  
                  </div>
                  
              
              </div>
    </div>
      
     
    )
}

export default ThankYouCard