import React from 'react'

import './shopItem.style.scss'

const ShopItem = ( {photo, name, title, price1, price2, label} ) => (

    <div className='product-container'>
        <div className = 'img-container'>
            <img src = {photo} alt = 'Widok produktu' />
            <div className ='label' style = {labelStyles[label]}> {label} </div> 

        </div>
        <p className ='name'>{name}</p>
        <p className ='description'>{title}</p>
        <p className = 'price' style={price2 ? {textDecoration: 'line-through'}: undefined}>{price1}</p>

        {
            price2 ? <p className = 'price'> {price2}</p> : null
        } 
    </div>
    
)

                                                                                                                                                                                                    ``
export default ShopItem

const labelStyles = {
    PROMOCJA: {
        background: "#E55D45",
        color: "#FFFFFF"
    },
    NOWOŚĆ: {
        background: "#15594D",
        color: "#FFFFFF"
    },
    WKRÓTCE: {
        background: "rgb(86, 89, 94)",
        color: "#FFFFFF"
    }
}



