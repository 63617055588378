import React from 'react'

import Spinner from '../spinner/Spinner'

import './form.style.scss'


const FormInfoBox = ( {infoBox} ) => {

    return(
        <div className = 'info-box' >

            {
                infoBox === 'Loading' ?
                <Spinner /> :
                <p style = {{paddingBottom: "10px"}} >{infoBox}</p>
            }


        </div>
    )
}

export default FormInfoBox
