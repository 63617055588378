import React from 'react'

import './spinner.style.scss'

const Spinner = () => (
    <div className = 'spinner'>

    </div>
)

export default Spinner