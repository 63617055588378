import React, {useState, useEffect} from 'react';
import './header.style.scss'
import Logo from '../../assets/logo.svg'
import {Link} from 'react-scroll'
import { Link as LinkRR, useLocation} from 'react-router-dom'


import MenuButton from '../menuButton/MenuButton'
import MenuList from '../menuList/MenuList';
import CartIcon from '../cartIcon/CartIcon'

const Header = ( { windowWidth, cartProducts, match} ) => {

useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll)
    }

}, [] )


const [menuOpened, setMenu] = useState(false);

const toggleMenu = () => {
    setMenu(menuOpened => !menuOpened)
}

const handleScroll = () => {
    setMenu(false)
}

    return (
        <header className = 'main-header'>
            <nav>
                {
                    useLocation().pathname === '/' ?
                    <Link activeClass="non-active" to="section1" spy={true} smooth={true} offset={-80} duration= {800}>
                        <img className = 'logo' src={Logo} alt='Plystation logo' />
                    </Link> 
                    :
                    <LinkRR to="/" >
                        <img className = 'logo' src={Logo} alt='Plystation logo' />
                    </LinkRR>  
                }

                <div style = {{display: "flex"}}>
                {
                    windowWidth < 1100 ?
                     <LinkRR className = 'cart' to="/checkout" style = {{paddingRight: "3px"}}>
                        <CartIcon cartProducts = {cartProducts}/>   
                    </LinkRR> 
                    :null
                }              
                <MenuButton menuOpened = {menuOpened} toggleMenu = {toggleMenu} windowWidth = {windowWidth} cartProducts = {cartProducts}/>
                </div>
           
                <MenuList match = {match} menuOpened = {menuOpened} windowWidth = {windowWidth} toggleMenu = {toggleMenu} cartProducts = {cartProducts}/>                            
            </nav>
        </header>


   
        )
}

export default Header
