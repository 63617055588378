import React from 'react'

import './section5.style.scss'


import Photo from '../../assets/mobile/section5_mobile.png'
import Photo1 from '../../assets/desktop/section5_desktop.png'

const Section5 = ( {windowWidth, history} ) => {

    const gotoShop = () => {
        history.push('sklep')
        window.scrollTo(0, 0)
    }
    
    return (
        <div className = 'wrapper'>
        <section id = 'section5'>
            <h3>Sklep</h3>
            <h2>Zamów biurko</h2>
        <div className ='container-5'>

            <div className = 'img-container'>
                <img src = {windowWidth > 800 ? Photo1 : Photo} alt = 'Deska Plystation'></img>
            </div>

        <div className = 'content-section5'>

            {
                windowWidth < 800 ?
                <div>
                    <h2 style = {{paddingBottom: '0'}}>Biurko samochodowe </h2>
                    <h2 style = {{paddingTop: '0'}} >PLY STATION</h2>
                </div> :
                <h2>Biurko samochodowe PLY STATION </h2>

            }
            <h3 style = {{color: 'black'}}>Opis</h3>

            <p>W PLY STATION cenimy solidne wykonanie, dbałość o detale i środowisko naturalne - i takie wartości przyświecały nam przy tworzeniu naszej 
                unikatowej deski- zabierz ją ze sobą w drogę i miej ją pod ręką zawsze gdy tego potrzebujesz!</p>
            <p>Sprawdź naszą ofertę i przekonaj się, że praca w samochodzie będzie wygodna jak nigdy przedtem.</p>
            <button onClick = {gotoShop}>przejdź do sklepu</button>
            </div>

        </div>
        </section>
    </div>
    )
    

}

export default Section5
