import React, {Component} from 'react'
import FormInfoBox from './FormInfoBox'
import PaczkomatsList from '../paczkomatsList/PaczkomatsList'
import StripeCheckoutButton from '../stripe/StripeButton'

import './inpost.style.scss'
import Regulamin from '../../assets/Regulamin_PLY_STATION.pdf'

import firebase from 'firebase';
require('firebase/functions');

const payment = (info) => {

    const initPayment = firebase.functions().httpsCallable('initPayment');
    initPayment(info).then(function(result){
        const stripe = window.Stripe('pk_live_51Hkns5LONRdAmV3Nd95abO8rQ6KEjSMeIyJOJZVwVUqw8xL7XwCMjpap5QDRgvC2YFF6SwQXpRRZfGsHlqXtaIvC00x7PXkMZm');
        stripe.confirmP24Payment(
            `${result.data.paymentIntent.client_secret}`,
            {
              payment_method: {
                billing_details: {
                  email: result.data.email
                }
              },
              return_url: 'https://plystation.eu/dziekujemy',
            }
          ).then(function(result) {
            if (result.error) {
              alert('Przepraszamy, coś poszło nie tak, spróbuj ponownie za chwilę' + 'Błąd: ' + result.error.message)
            }
          });
    })
}


const sendMail = async currState => {
    const sendMail = firebase.functions().httpsCallable('sendMailPayment');
    sendMail(currState).then(function(result) {
        if (result.data) {
                payment(result.data)   
        } 

        else {
            alert('Przepraszamy, coś poszło nie tak, spróbuj ponownie za chwilę')
        }

    })
}


export class Inpost extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            miasto: '',
            kodPocztowy: '',
            idPaczkomatu: '',
            paczkomaty: [],
            chosenPaczkomat: {
                name: '',
                address1: '',
                address2: '',
                detail: '',
            },
            deliveryAddressCity: '',
            deliveryAddressStreet: '',
            deliveryAddressNumber: '',
            deliveryAddressZipCode: '',
            deliveryAddressAdditionalInfo: '', 
            chosenDelivery: "Paczkomaty InPost",
            name: '',
            email: '',
            phone: '',
            regulamin: false,
            lastStep: '',
            dis: false,
            paczkomatyErrors: "",
            paymentMethod: "Szybki przelew - Przelewy24",
            cardPayment: false,
            totalPrice: 0,
            cartItems: this.props.cartProducts,
            faktura: false,
            companyName: '',
            NIP: '',
            companyStreet: '',
            companyCode: '',
            companyCity: '',
        }
    }

    setLoading = () => {
        this.setState({lastStep: 'Loading'})
    }

    componentDidUpdate(prevProps) {
        if(this.props.cartProducts.length !== 0 ) {

            if (prevProps.cartProducts[0][1] !== this.props.cartProducts[0][1]) {
                this.calculatePrice();
              }
            }
      }

    componentDidMount() {
        this.calculatePrice()
    } 

    userInput = (e) => {
        
        if(+e.target.value[0] || e.target.value[0] == '0') {

            let kodPocztowy =''

            if(e.target.value.length === 6 && e.target.value[2] === '-' ) {
                kodPocztowy = e.target.value
            } else if (e.target.value.length === 5) {
                kodPocztowy =  e.target.value.slice(0,2) + '-' + e.target.value.slice(2)
            } 

            this.setState({kodPocztowy: kodPocztowy})
            this.setState({miasto: ''})
            this.setState({idPaczkomatu: ''})


        } else if ( /\d/.test(e.target.value) ) {

            let idPaczkomatu = e.target.value.toUpperCase()

                this.setState({kodPocztowy: ''})
                this.setState({miasto: ''})
                this.setState({idPaczkomatu: idPaczkomatu})

        } else {

            let miasto = e.target.value.split('').map( (el,ix,arr) => ix == 0 || arr[ix-1] == ' ' ? el.toUpperCase() : el).join('')
            this.setState({miasto: miasto})
            this.setState({kodPocztowy: ''})
            this.setState({idPaczkomatu: ''})

        }
    }

    handleKeyPress = (e) => {
        if(e.charCode == 13){
            this.inpost()
          }
    }

    inpost = () => {

        let query

        if (this.state.miasto) {
            query = `?city=${this.state.miasto}`
        } else if (this.state.kodPocztowy) {
            query = `?relative_post_code=${this.state.kodPocztowy}&max_distance=4000`;
        } else if (this.state.idPaczkomatu) {
            query = `?name=${this.state.idPaczkomatu}`
        } else {
            this.setState({paczkomatyErrors: "Sprawdź poprawność wprowadzonych danych. Wpisz Kod pocztowy lub miasto lub nazwę paczkomatu."})
        }

        if(!query) {return}

        let pages = 0 
        let paczkomaty = []

        fetch(`https://api-shipx-pl.easypack24.net/v1/points${query}&per_page=500`)
            .then(res => res.json())
            .then(data => {
                pages = data.total_pages

                if(data.count === 0 ) {
                    this.setState({paczkomatyErrors: "Nie znaleźliśmy paczkomatu spełniającego wpisane kryteria. Spróbuj wpisać inne dane."})
                    return
                }

                for(let i = 1 ; i <= pages ; i++) {
                    fetch(`https://api-shipx-pl.easypack24.net/v1/points${query}&page=${i}&per_page=500`)
                    .then(res => res.json())
                    .then(data => {
                        this.setState({paczkomatyErrors: ""})
                        data.items.forEach(el => paczkomaty.push(el))
                        this.setState({paczkomaty: paczkomaty})
                    })
                    .catch(err => this.setState({paczkomatyErrors: "Coś poszło nie tak, spróbuj ponownie za chwilę."}))             
                }
            })
        }

        choosePaczkomat = (e) => {
            this.setState( {chosenPaczkomat: {
                ...this.state.chosenPaczkomat,
                name: e.target.name,
                address1: e.target.attributes[1].nodeValue,
                address2: e.target.attributes[2].nodeValue,
                detail: e.target.attributes[3].nodeValue,
            }})
        }

        clearPaczkomat = () => {
            this.setState( {chosenPaczkomat: '' })
        }

        setDelivery = (e) => {
            this.setState( {chosenDelivery: e.target.value} )
            this.calculatePrice()
        }

        setPaymentMethod = (e) => {
            this.setState( { paymentMethod: e.target.value} )
            this.setState( {cardPayment: false})
            this.setState( {lastStep: ''} )
        }

        toggleCheckBox = (e) => {
            e.target.name === 'regulamin' ?
                this.setState( {regulamin: !this.state.regulamin} )
            : this.setState( {rodo: !this.state.rodo} )
        }

        toggleInvoiceCheckbox = (e) => {
            this.setState({faktura: !this.state.faktura,
                companyName: '',
                NIP: '',
                companyCity: '',
                companyCode: '',
                companyCity: '',
            })
        }    

        setName = e => (
            this.setState({ name:e.target.value })
        )
    
        setEmail = e => (
            this.setState({ email:e.target.value })
        )
    
        setPhone = e => (
            this.setState({ phone: e.target.value })
        )

        setYourAddress = e => (
            this.setState({ youraddress: e.target.value })
        )

        setDeliveryAddress = e => {
            const { target: { name, value } } = e
            this.setState({ [name]: value })

          }

          setCompanyName = e => (
            this.setState({ companyName: e.target.value })
        )

        setNIP = e => (
            this.setState({ NIP: e.target.value })
        )

        setCompanyStreet = e => (
            this.setState({ companyStreet: e.target.value })
        )

        setCompanyCode = e => (
            this.setState({ companyCode: e.target.value })
        )

        setCompanyCity = e => (
            this.setState({ companyCity: e.target.value })
        )
   
    checkChaptcha = async () => {
        this.setState({lastStep: 'Loading'})
        this.setState({dis: true})
        const frontendData = {
            currState: this.state,
            currProducts: this.props.cartProducts,
            orderID: `${Date.now()}${Math.ceil(Math.random()*99)}`
        }
        
        const SITE_KEY = '6LdDYt8ZAAAAAI3uJwZrmTUnaKZzO9MiFyUTGZyd'
        await window.grecaptcha.ready(() => {
        window.grecaptcha
            .execute(SITE_KEY, { action: "captcha" })
            .then(function(token) {
            const checkChaptcha = firebase.functions().httpsCallable('checkCaptcha');
            checkChaptcha(token).then(function(result) {
                if (result.data) {
                    sendMail(frontendData)
                } else {
                    alert('Przepraszamy, coś poszło nie tak, spróbuj ponownie za chwilę')
                }
            })
        });
        })
    } 

    checkValues = () => {

        if(this.props.cartProducts.length == 0 ) {
            this.setState({lastStep: 'Twój koszyk jest pusty, dodaj produkty w celu dokonania zakupu.'})
            return
        }

        if(this.state.regulamin && this.state.name && this.state.email && this.state.phone) {
            const regex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)

            if (!regex.test(this.state.email)) {
                this.setState({lastStep: 'Nieprawidłowy adres email.'})
                return
            }          

            if(this.state.chosenDelivery === 'Paczkomaty InPost') {

                if (this.state.chosenPaczkomat.name) {
                    this.setState({ cardPayment: true})
                    this.setState({ lastStep: "Aby kontynuować płatność kliknij guzik ponownie"})
                    this.calculatePrice()
                } else {
                    this.setState({lastStep: 'Prosimy o uzupełnienie wszystkich wymaganych danych.'}) 
                }

            }               

            if(this.state.chosenDelivery === 'Kurier InPost') {


                if (this.state.deliveryAddressCity && this.state.deliveryAddressStreet && 
                    this.state.deliveryAddressNumber && this.state.deliveryAddressZipCode) {
                        this.setState({ cardPayment: true})
                        this.setState({ lastStep: "Aby kontynuować płatność kliknij guzik ponownie"})
                        this.calculatePrice()
                    } else {
                        this.setState({lastStep: 'Prosimy o uzupełnienie wszystkich wymaganych danych.'})
                    }
            }
        }

         else {
            this.setState({lastStep: 'Prosimy o uzupełnienie wszystkich wymaganych danych.'})
        }    
    }

        makeTransaction = () => {

            if(this.props.cartProducts.length == 0 ) {
                this.setState({lastStep: 'Twój koszyk jest pusty, dodaj produkty w celu dokonania zakupu.'})
                return
            }

            if(this.state.regulamin && this.state.name && this.state.email && this.state.phone) {
                const regex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)

                if (!regex.test(this.state.email)) {
                    this.setState({lastStep: 'Nieprawidłowy adres email.'})
                    return
                }          

                if(this.state.chosenDelivery === 'Paczkomaty InPost') {
                    this.state.chosenPaczkomat.name ? 
                    this.checkChaptcha() : this.setState({lastStep: 'Prosimy o uzupełnienie wszystkich wymaganych danych.'}) 
                }               

                if(this.state.chosenDelivery === 'Kurier InPost') {
                    (this.state.deliveryAddressCity && this.state.deliveryAddressStreet 
                        && this.state.deliveryAddressNumber && this.state.deliveryAddressZipCode) ? 
                        this.checkChaptcha() : this.setState({lastStep: 'Prosimy o uzupełnienie wszystkich wymaganych danych.'})
                }
            }
    
             else {
                this.setState({lastStep: 'Prosimy o uzupełnienie wszystkich wymaganych danych.'})
            }    
        }

        
        calculatePrice = () => {
            let price;
            let totalPrice;

            if(this.state.chosenDelivery === "Paczkomaty InPost") {
                price = this.props.settings.pricePaczkomat
            }

            if(this.state.chosenDelivery === "Kurier InPost") {
                price = this.props.settings.priceKurier
            }      

            if(this.props.cartProducts.length !== 0 ) {

                const productPrice = this.props.products[0].price2 ?
                this.props.products[0].price2.slice(0,-2) :
                this.props.products[0].price1.slice(0,-2)
                const cartValue = this.props.cartProducts[0][1] * productPrice    
                totalPrice = price + cartValue
            }

           this.setState({totalPrice: totalPrice})
        }

    render() {
        return (
            <div className ='inpost'>
            <h3>Dane osobowe</h3>  
            <form>
                <label htmlFor="name">IMIĘ I NAZWISKO(*)</label>
                <input type="text" name="name" required onChange = {this.setName}/>
                <label for="email">EMAIL(*)</label>
                <input type="email" name="email" required onChange = {this.setEmail}/> 
                <label htmlFor="phone">NUMER TELEFONU(*)</label>
                <input type="text" name="phone" required onChange = {this.setPhone}/>
                <div className = 'check-cont' style = {{
                    paddingLeft: '0px'
                }} >
                <div>
                    <input type="checkbox" name = 'faktura' onChange = {this.toggleInvoiceCheckbox}/>
                    <label>Chcę otrzymać fakturę</label>      
                </div>   
            </div>
            {
                    this.state.faktura ? 
                    <>
                    <label htmlFor="companyName">NAZWA FIRMY(*)</label>
                    <input type="text" name="companyName" required onChange = {this.setCompanyName}/>
                    <label htmlFor="NIP">NIP(*)</label>
                    <input type="text" name="NIP" required onChange = {this.setNIP}/>
                    <label htmlFor="companyStreet">ULICA I NUMER(*)</label>
                    <input type="text" name="companyStreet" required onChange = {this.setCompanyStreet}/>
                    <label htmlFor="companyCode">KOD POCZTOWY(*)</label>
                    <input type="text" name="companyCode" required onChange = {this.setCompanyCode}/>
                    <label htmlFor="companyCity">MIASTO(*)</label>
                    <input type="text" name="companyCity" required onChange = {this.setCompanyCity}/>
                    </> : null
                } 
            </form>
            <h3>Sposób dostawy</h3>
          
            <form onChange = {this.setDelivery} >
                <div className = 'radio-cont'>
                    <input type="radio" name ='dostawa' value="Paczkomaty InPost" checked={this.state.chosenDelivery === 'Paczkomaty InPost'}/>
                    <label for="Paczkomaty InPost" style = {{marginLeft: '5px'}}>
                        {`Paczkomaty InPost ${this.props.settings.pricePaczkomat === 0 ? '(DOSTAWA GRATIS)': `(+ ${this.props.settings.pricePaczkomat.toFixed(2)} zł)`}`}
                    </label>
                </div>

                
                <div className = 'radio-cont'>
                    <input type="radio" name ='dostawa' value="Kurier InPost" />
                    <label for="Kurier InPost" style = {{marginLeft: '5px'}} >
                    {`Kurier InPost ${this.props.settings.priceKurier === 0 ? '(DOSTAWA GRATIS)': `(+ ${this.props.settings.priceKurier.toFixed(2)} zł)`}`}
                    </label>
                    </div>
               
       
            </form>         
                {
                    this.state.chosenDelivery === 'Paczkomaty InPost' ? 
                    <div>
                    {
                        !this.state.chosenPaczkomat.name ?
    
                        <div>
                        <form className = 'address-box' onSubmit = {(e)=>e.preventDefault()} onKeyPress={this.handleKeyPress}  >
                            <label>WPISZ MIASTO LUB KOD POCZTOWY LUB NAZWĘ PACZKOMATU(*)</label>
                            <input type='text' className ='cityInput' onChange = {this.userInput} />                      
                        </form>
                         
                        <p style = {{padding: "10px 1.5rem", color: "#E55D45"}}>{this.state.paczkomatyErrors}</p>
                           
                            <button  onClick = {this.inpost} style = {{marginTop: '1.5rem'}}>Znajdź swój paczkomat!</button>
                            <PaczkomatsList paczkomaty = {this.state.paczkomaty} choosePaczkomat = {this.choosePaczkomat}/>           
                        </div>
                        :
                        <div >                
                            <h3>Wybrany paczkomat</h3>
                            <div className = 'twoj-paczkomat'>
                                <div className ='wybrany-paczkomat'>  
                                    <h2> {this.state.chosenPaczkomat.name}</h2>
                                    <p>{this.state.chosenPaczkomat.address1}</p>
                                    <p>{this.state.chosenPaczkomat.address2}</p>
                                    <p>{this.state.chosenPaczkomat.detail}</p>
                                </div> 
                            <button className ='in-sub' onClick = {this.clearPaczkomat} >Wybierz inny</button>
                            </div>
                        </div>
                    
                    }
                    </div>
                    : 
                    
                    <form className = 'address-box'>
                        <label for ='deliveryAddressCity'>MIASTO(*)</label>
                        <input type="text" name="deliveryAddressCity" required onChange ={this.setDeliveryAddress}/>
                        <label for ='deliveryAddressStreet'>ULICA(*)</label>
                        <input type="text" name="deliveryAddressStreet" required onChange ={this.setDeliveryAddress}/>
                        <label for ='deliveryAddressNumber'>NUMER DOMU/MIESZKANIA(*)</label>
                        <input type="text" name="deliveryAddressNumber" required onChange ={this.setDeliveryAddress}/>
                        <label for ='deliveryAddressZipCode'>KOD POCZTOWY(*)</label>
                        <input type="text" name="deliveryAddressZipCode" required onChange ={this.setDeliveryAddress}/>
                        <label for ='deliveryAddressAdditionalInfo'>DODATKOWE INFORMACJE (np. nazwa firmy, piętro)</label>
                        <input type="text" name="deliveryAddressAdditionalInfo" required onChange ={this.setDeliveryAddress}/>   
                    </form>
                }

            <h3 style = {{paddingTop: '1.5rem'}}>Zgody formalne</h3>
            <div className = 'check-cont' >
                <div>
                    <input type="checkbox" name = 'regulamin' onChange = {this.toggleCheckBox}/>
                    <label >Oświadczam, że zapoznałem/am się z treścią <a  href={Regulamin} target = "_blank" rel="noopener noreferrer" style = {{cursor: "pointer", color: "#15594D"}}>regulaminu</a> i akceptuję jego treść.(*)</label>      
                                </div>   

                      
            </div>
            <div className = 'captcha-info captcha-checkout'>
                        Nasza strona jest chroniona przez zabezpieczenie reCAPTCHA i Google. Obowiązują:
                            <a href="https://policies.google.com/privacy" target="_blank"> Polityka Prywatności</a> oraz
                            <a href="https://policies.google.com/terms" target="_blank"> Warunki korzystania.</a>
            </div>     
            <h3 style = {{paddingBottom: '2rem', fontSize: '12px', paddingLeft: '1.5rem'}}>Pola oznaczone (*) są wymagane.</h3>
            <h3>Płatność</h3>

            <form onChange = {this.setPaymentMethod} >
                <div className = 'radio-cont'>
                    <input type="radio" name ='płatność' value="Szybki przelew - Przelewy24" checked={this.state.paymentMethod === 'Szybki przelew - Przelewy24'}/>
                    <label for="Szybki przelew - Przelewy24" style = {{marginLeft: '5px'}}>
                        Szybki przelew - Przelewy24 
                    </label>
                </div>
                
                <div className = 'radio-cont'>
                    <input type="radio" name ='płatność' value="Płatność kartą" />
                    <label for="Płatność kartą" style = {{marginLeft: '5px'}} >
                        Płatność kartą
                    </label>
                    </div>

            </form>

            <label style = {{padding: "0rem 1.5rem"}}>Operatorem twojej płatności są: <a  href=
                "https://stripe.com/en-pl"
             target = "_blank" rel="noopener noreferrer" style = {{cursor: "pointer", color: "#15594D"}}>Stripe</a> oraz <a  href=
             "https://www.przelewy24.pl/"
          target = "_blank" rel="noopener noreferrer" style = {{cursor: "pointer", color: "#15594D"}}>Przelewy24</a></label>
            
            <FormInfoBox infoBox = {this.state.lastStep}/>


                {
                    this.state.paymentMethod === "Szybki przelew - Przelewy24" ?
                 <button className ='last-btn' onClick = {this.makeTransaction} disabled = {this.state.dis}>KUPUJĘ I PŁACĘ</button>
                    : null
                }

                {
                    this.state.paymentMethod === "Płatność kartą" ?
                    <div>
                    {
                        !this.state.cardPayment ? 
                        <button className ='last-btn' onClick = {this.checkValues}>PŁATNOŚĆ KARTĄ - WERYFIKACJA DANYCH</button>
                        : <StripeCheckoutButton className ='last-btn' email = {this.state.email} 
                        price= {this.state.totalPrice} currState = {this.state} currProducts = {this.props.cartProducts}
                        setLoading = {this.setLoading}               
                        />
                    }
                    </div>
                    : null
                }
            </div>
        )
    }
}

export default Inpost

