import React, {useState, useEffect} from 'react';
import {Route, Switch } from 'react-router-dom'

import Captcha from './components/captcha/Captcha'
import Spinner from './components/spinner/Spinner'

//pages
import Homepage from './pages/Homepage'
import Checkout from './pages/Checkout'
import ShopPage from './pages/ShopPage'
import ProductPage from './pages/ProductPage'
import ThankYou from './pages/ThankYou'
import CookiePage from './pages/CookiePage'
import ThankYouCard from './pages/ThankYouCard'

import Cookie from './components/cookies/Cookie'
import ShopTopIMG from './assets/desktop/shop_top.png' 

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions'



function App() {

const [windowWidth, setWindowWidth] = useState(window.innerWidth);
const [loading, setLoading] = useState(true);

const firebaseConfig = {
  apiKey: "AIzaSyAEGcHJ4mZpF3S3J6tTIDKUU2AxM_HmC5g",
  authDomain: "ply-station-01.firebaseapp.com",
  databaseURL: "https://ply-station-01.firebaseio.com",
  projectId: "ply-station-01",
  storageBucket: "ply-station-01.appspot.com",
  messagingSenderId: "777294297789",
  appId: "1:777294297789:web:d1572a5494746741d755d4",
  measurementId: "G-GTDCKX12GT"

};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  }

  // firebase.functions().useEmulator("localhost", 5001);

  const firestore = firebase.firestore();


useEffect( () => {
    window.addEventListener('resize', handleResize)

    firestore.collection('settings').get().then((snapshot) => {
      snapshot.docs.forEach(doc => {
        const settings = doc.data()   
        setSettings(settings)
      })

})


      firestore.collection('products').get().then((snapshot) => {
        snapshot.docs.forEach(doc => {
          const docObject = doc.data()
          const productsArray = []

      
          Object.keys(docObject).forEach(key => {
            productsArray.push((key, docObject[key]));
          })

          setProducts(productsArray.sort( (a,b) => a.id -b.id))
          setLoading(false)
        })


  })

      const script = document.createElement("script")
      script.src = 'https://js.stripe.com/v3/'
      document.head.appendChild(script)

    return () => {
        window.removeEventListener('resize', handleResize)
    }

}, [])


const handleResize = () => {
  setWindowWidth(window.innerWidth)
}

const [products, setProducts] = useState([])
const [cartProducts, setCartProducts] = useState( [] )
const [settings, setSettings] = useState( {} )

const addToCart = (productID, quantity) => {
      setCartProducts(prev => {         
        if (prev.length < 1) {
          return [...prev, [productID, quantity] ] 
        } else {
          return prev.map( product => product[0] === productID ? [productID, product[1]+quantity] : product)   
        }
      })
    }


const deleteFromCart = (productID) => {
  setCartProducts(prev => prev.filter(product => product[0] != productID))
}

const plusQuantity = (productID) => {
    setCartProducts(prev => prev.map( product => product[0] == productID ? [productID, product[1] + 1] : product))
  }


const minusQuantity = (productID) => {
  
  setCartProducts(prev =>
    prev.map( product => product[0] == productID ? 
      product[1] === 1 ?
      [productID, product[1]]:
      [productID, product[1] - 1] : product))
}
    

return (
    <div className="App">
            <Captcha />
            <Cookie />

    {
      loading ? <div className = 'loading-start'><Spinner /></div> :
      <Switch>
        <Route exact path ='/' render = {(props) => <Homepage {...props} windowWidth = {windowWidth} cartProducts = {cartProducts} settings = {settings}/>}/>
        <Route exact path ='/sklep' render = {(props) => <ShopPage {...props} windowWidth = {windowWidth} products = {products} cartProducts = {cartProducts} shopimage = {ShopTopIMG}/>} />
        <Route exact path ='/checkout' render = {(props) => <Checkout {...props} windowWidth = {windowWidth} cartProducts = {cartProducts} 
        products = {products} plusQuantity = {plusQuantity} minusQuantity = {minusQuantity} deleteFromCart = {deleteFromCart} settings = {settings}/> } />
        <Route exact path = '/sklep/:productId' render = {(props) => <ProductPage {...props} products = {products} addToCart = {addToCart} windowWidth = {windowWidth} cartProducts = {cartProducts}/>} />
        <Route path = '/cookies' component = {CookiePage} exact/>
        <Route path = '/dziekujemy' component = {ThankYou} />
        <Route path = '/card/dziekujemy' component = {ThankYouCard} exact />

      </Switch>
    }

        
    </div>
  );
}

export default App;


// [
//   {
//     id: 0,
//     name: 'Biurko samochodowe #PSD#01',
//     title:'do samochodów osobowych i kombivan',
//     photo: 'https://i.ibb.co/0JyC3px/product1-mobile.png',
//     price1: '109 zł',
//     price2: '98 zł',
//     label: 'PROMOCJA',
//     description: [
//       'Biurko samochodowe PLY STATION #PSD#01 to model stworzony do korzystania w samochodach osobowych i kombivanach (np. Citroen Berlingo).',
//       'W kilka sekund deska założona na kierownicę zmienia się w ergonomiczne biurko, na którym możesz postawić laptop i wygodnie napisać maila, przeszukać internet lub przygotować zestawienie w arkuszu kalkulacyjnym.',
//       'Spróbuj oprzeć tablet lub smartfon w specjalnym slocie i przekonaj się jak wygodnie przeprowadzić wideokonferencję w aucie lub przejrzeć materiały przed spotkaniem z klientem - jednocześnie sporządzając notatki albo wpisując spotkanie do kalendarza.',
//       'Możesz także podłączyć klawiaturę bluetooth do swojego tabletu i pracować efektywnie jak na notebooku!',
//       'W czasie śniadania, lunchu lub kiedy tylko będziesz mieć ochotę - deska PLY STATION zamieni się w praktyczny stolik, na którym bezproblemowo postawisz swój posiłek i napój, bez konieczności niewygodnego trzymania go na kolanach lub fotelu pasażera.',
//       'Biurko samochodowe jest wykonane ze sklejki brzozowej o grubości 12mm. Pokryte lakierem bezpiecznym także dla dzieci spełniającym m.in. wymagania normy PN-EN 71.3- Bezpieczeństwo Zabawek. Wykończone ręcznie.',
//       'Wykonane w 100% w Polsce z dbałością o środowisko.',
//     ]
//   },
//   {
//     id: 1,
//     name: 'Biurko samochodowe #PSD#02',
//     title:'do samochodów dostawczych i vanów',
//     photo: 'https://i.ibb.co/LZVjWt0/product2-mobile.png',
//     price1: 'Wkrótce w ofercie',
//     price2: '',
//     label: 'WKRÓTCE',
//     description: [
//       'Biurko samochodowe PLY STATION #PSD#02to model stworzony do korzystania w samochodach dostawczych i vanach.',
//       'W kilka sekund deska założona na kierownicę zmienia się w ergonomiczne biurko, na którym możesz postawić laptop i wygodnie napisać maila, przeszukać internet lub przygotować zestawienie w arkuszu kalkulacyjnym.',
//       'Spróbuj oprzeć tablet lub smartfon w specjalnym slocie i przekonaj się jak wygodnie przeprowadzić wideokonferencję w aucie lub przejrzeć materiały przed spotkaniem z klientem - jednocześnie sporządzając notatki albo wpisując spotkanie do kalendarza.',
//       'Możesz także podłączyć klawiaturę bluetooth do swojego tabletu i pracować efektywnie jak na notebooku!',
//       'W czasie śniadania, lunchu lub kiedy tylko będziesz mieć ochotę - deska PLY STATION zamieni się w praktyczny stolik, na którym bezproblemowo postawisz swój posiłek i napój, bez konieczności niewygodnego trzymania go na kolanach lub fotelu pasażera.',
//       'Biurko samochodowe jest wykonane ze sklejki brzozowej o grubości 12mm. Pokryte lakierem bezpiecznym także dla dzieci spełniającym m.in. wymagania normy PN-EN 71.3- Bezpieczeństwo Zabawek. Wykończone ręcznie.',
//       'Wykonane w 100% w Polsce z dbałością o środowisko.',
//     ]
//   },
//   {
//     id: 2,
//     name: 'Biurko samochodowe #PSD#indie',
//     title:'Z logo Twojej firmy!',
//     photo: 'https://i.ibb.co/wrJpTHm/product3-mobile.png',
//     price1: 'Zapytaj o wycenę',
//     price2: '',
//     label: 'NOWOŚĆ',
//     description: [
//       'Prowadzisz firmę? Szukasz gadżetu reklamowego, który zaskoczy Twoich kontrahentów? A może chcesz poprawić komfort i efektywność pracy Twojego zespołu sprzedażowego? Biurko samochodowe PLY STATION #PSD#indie to doskonały nośnik wizerunku Twojej marki, a zarazem akcesorium poprawiające wydajność Twojego zespołu!',
//       'Indywidualne wykonanie z logo firmy lub grafiką jaką sobie wymarzysz, sprawi że biurko będzie świetnym gadżetem reklamowym, z którego korzystanie będzie przyjemnością.',
//       'Deska PLY STATION założona na kierownicę w kilka sekund zmienia się w ergonomiczne biurko, na którym możesz postawić laptop i wygodnie napisać maila, przeszukać internet lub przygotować zestawienie w arkuszu kalkulacyjnym.',
//       'Spróbuj oprzeć tablet lub smartfon w specjalnym slocie i przekonaj się jak wygodnie przeprowadzić wideokonferencję w aucie lub przejrzeć materiały przed spotkaniem z klientem - jednocześnie sporządzając notatki albo wpisując spotkanie do kalendarza.',
//       'Możesz także podłączyć klawiaturę bluetooth do swojego tabletu i pracować efektywnie jak na notebooku!',
//       'Biurko samochodowe jest wykonane ze sklejki brzozowej o grubości 12mm. Pokryte lakierem bezpiecznym także dla dzieci spełniającym m.in. wymagania normy PN-EN 71.3- Bezpieczeństwo Zabawek. Wykończone ręcznie.',
//       'Wykonane w 100% w Polsce z dbałością o środowisko.',
//     ]
//   },
// ]