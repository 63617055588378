import React from 'react'
import Header from '../components/header/Header'
import Section1 from '../components/section1/Section1'
import Section2 from '../components/section2/Section2'
import Section3 from '../components/section3/Section3'
import Section4 from '../components/section4/Section4'
import Section5 from '../components/section5/Section5'
import Kontakt from '../components/section6-kontakt/Kontakt'
import ContactForm from '../components/contactForm/ContactForm'
import Footer from '../components/footer/Footer'
import Modal from '../components/modal/Modal'


const Homepage = ( {windowWidth, history, cartProducts, match, settings} ) => {


  return (

  <React.Fragment>
    <Header windowWidth = {windowWidth} cartProducts = {cartProducts} match = {match}/>
    <Section1 windowWidth = {windowWidth} history = {history}/>
    {
      settings.popUp ? <Modal text = {settings.popUpText}/> : null
    }
    <Section2 windowWidth = {windowWidth}/>
    <Section3 windowWidth = {windowWidth}/>
    <Section4 windowWidth = {windowWidth}/>
    <Section5 windowWidth = {windowWidth} history = {history}/>
    
    <div className = 'footer-container-wrap'>
    <div className = 'contact-form-footer'>
        <Kontakt para1 = 'Zależy nam, aby deska PLY STATION zapewniła Ci wygodę i komfort pracy. Jeśli masz jakiekolwiek pytania- skorzystaj z naszego formularza kontaktowego.'
                para2 = 'Chciałbyś złożyć zamówienie niestandardowe? Napisz do nas!'            
        />
        <ContactForm />
        <Footer match = {match} history = {history}/>
    </div>
    </div>

  </React.Fragment>

)
  }
export default Homepage