import React from 'react'
import Logo from '../../assets/logo.svg'
import Regulamin from '../../assets/Regulamin_PLY_STATION.pdf'
import Karta_produktu from '../../assets/karta_produktu.pdf'

import { Link } from "react-scroll";
import { Link as LinkRR} from 'react-router-dom'

import { useLocation } from 'react-router-dom'



import './footer.style.scss'


const Footer = () => (
        <footer>
            <nav>
                <ul className = 'footer-nav'>   
                    {
                        useLocation().pathname === '/' ?
                    <Link activeClass="non-active" to="section1" spy={true} smooth={true} offset={0} duration= {800}>
                        <img className = 'logo' src={Logo} alt='Plystation logo' />
                    </Link> 
                    :
                    <LinkRR to="/" >
                        <img className = 'logo' src={Logo} alt='Plystation logo' onClick = {() => window.scrollTo(0, 0)}/>
                    </LinkRR> 
                    }    

                <div>
                {
        useLocation().pathname !== '/' ? null : 
        <Link activeClass="active" to="section2" spy={true} smooth={true}  offset={-120} 
        duration= {800}>
            <li>biurka samochodowe</li>
        </Link>
    }
        
    {
        useLocation().pathname !== '/' ? null : 
        <Link activeClass="active" to="section3" spy={true} smooth={true} offset={-120} duration= {800}>
            <li>jak to działa?</li>
        </Link>

    }
    {
        useLocation().pathname !== '/'? null : 
        <Link activeClass="active" to="section4" spy={true} smooth={true} offset={-120} duration= {800}>
            <li>o nas</li>
        </Link>
    }

        <LinkRR to="/sklep" onClick = {window.scroll(0,0)}>
            <li>sklep</li>
        </LinkRR>
    
    {
        useLocation().pathname !== '/' ? null : 
        <Link activeClass="active" to="kontakt" spy={true} smooth={true} offset={-120} duration= {800}>
            <li>kontakt</li>
        </Link>
    }

        <LinkRR to="/checkout" onClick = {window.scroll(0,0)} >
            <li>koszyk</li>    
        </LinkRR> 


        <a  href={Regulamin} target = "_blank" rel="noopener noreferrer" > <li>Regulamin</li></a>
        <a  href={Karta_produktu} target = "_blank" rel="noopener noreferrer" > <li>Karta produktu</li></a>



        <a className = 'social' style = {{display: "flex"}} href='https://www.facebook.com/PlyStationDesks' rel="noopener noreferrer" target="_blank"><span style = {{color: "white", marginRight: "3px"}} className="fab fa-facebook-square"></span><li>facebook.com/PlyStationDesks</li></a>
   
        <a className = 'social' style = {{display: "flex"}} href='https://www.instagram.com/plystationdesks/' rel="noopener noreferrer" target="_blank"><span style = {{color: "white", marginRight: "3px"}} className="fab fa-instagram" ></span><li>instagram.com/plystationdesks</li></a>    


    </div>

                </ul>
            </nav>       
        </footer>
)


export default Footer


