import React from 'react'
import { Link } from "react-scroll";
import { Link as LinkRR, useLocation} from 'react-router-dom'
import CartIcon from '../cartIcon/CartIcon'


import './menuList.style.scss'

const MenuList = ( {menuOpened, windowWidth, toggleMenu, cartProducts} ) => (
    <ul className = 'main-menu' style={!menuOpened & windowWidth < 1100 ? {display: 'none' } : {display: 'flex'}} >
          
    {
        useLocation().pathname !== '/' ? null : 
        <Link activeClass="active" to="section2" spy={true} smooth={true}  offset={-120} 
        duration= {800} onClick = {toggleMenu}>
            <li>biurka samochodowe</li>
        </Link>
    }
        
    {
        useLocation().pathname !== '/' ? null : 
        <Link activeClass="active" to="section3" spy={true} smooth={true} offset={-120} duration= {800} onClick = {toggleMenu}>
            <li>jak to działa?</li>
        </Link>
    }
    {
        useLocation().pathname !== '/'? null : 
        <Link activeClass="active" to="section4" spy={true} smooth={true} offset={-120} duration= {800} onClick = {toggleMenu}>
            <li>o nas</li>
        </Link>
    }

        <LinkRR to="/sklep" onClick = {toggleMenu}>
            <li>sklep</li>
        </LinkRR>
    
    {
        useLocation().pathname !== '/' ? null : 
        <Link activeClass="active" to="kontakt" spy={true} smooth={true} offset={-150} duration= {800} onClick = {toggleMenu}>
            <li>kontakt</li>
        </Link>
    }

    <div className = 'cart-menu-icon'>
        <LinkRR className = 'cart' to="/checkout" onClick = {toggleMenu}>
            <li>koszyk </li>    
        </LinkRR>    

        <LinkRR className = 'cart' to="/checkout" onClick = {toggleMenu}>
            <CartIcon cartProducts = {cartProducts}/>   
        </LinkRR>    
    </div>
         
     
    </ul>

)

export default MenuList