import React from 'react'

import Inpost from '../components/inpost/Inpost'
import Cart from '../components/cart/Cart'
import Header from '../components/header/Header'


import './checkout.style.scss'



const Checkout = ( {windowWidth, cartProducts, products, deleteFromCart, plusQuantity, minusQuantity, match, history, settings} ) => {

    return (
        <React.Fragment>
        <Header cartProducts = {cartProducts} match = {match} windowWidth = {windowWidth}/>
        <div className ='checkout-page'>
            <div className ='middle'>
                <Cart cartProducts = {cartProducts} products = {products} deleteFromCart = {deleteFromCart} plusQuantity = {plusQuantity} minusQuantity = {minusQuantity}/> 
                <Inpost cartProducts = {cartProducts} settings = {settings} products = {products}/>
            </div>       
        </div>

        </React.Fragment>
    )

}


export default Checkout