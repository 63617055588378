import React from 'react'

import './paczkomatsList.style.scss'

const PaczkomatsList = ({paczkomaty, choosePaczkomat}) => (
 
    <div className ='paczkomat-list'>
        {
        paczkomaty.map(paczkomat => (
        
            <div>
                <div className ='top-paczko' >
                    <h1>{paczkomat.name}</h1>
                    <button onClick = {choosePaczkomat} 
                            name = {paczkomat.name}
                            address1 = {paczkomat.address.line2}
                            address2 = {paczkomat.address.line1}
                            detail = {paczkomat.location_description}
                            >Wybierz
                    </button>
                </div>

                <p>{paczkomat.address.line2}</p>
                <p>{paczkomat.address.line1}</p>
                <p>{paczkomat.location_description}</p>
                <div className = 'separate'></div>

            </div>
        
        
        
        
        
        ))
        }

    </div>

)
    
export default PaczkomatsList