import React, {useState} from 'react'

import Header from '../components/header/Header'
import Slider from '../components/slider/Slider'
import Footer from '../components/footer/Footer'
import Kontakt from '../components/section6-kontakt/Kontakt'
import ContactForm from '../components/contactForm/ContactForm'
import CartQuantity from '../components/cartQuantity/CartQuantity'

import Karta_produktu from '../assets/karta_produktu.pdf'

import './productPage.style.scss'

const ProductPage  = ( {windowWidth, products, match, addToCart, cartProducts, history} ) => {

    const [productID] = useState(match.url[match.url.length-1])    

const sendToCart = () => {
    const quantityDIV = document.querySelector('#quantityToCart')
    const productQuantity = parseInt(quantityDIV.textContent)
    addToCart(productID, productQuantity)
}

    return (
        <React.Fragment>
        <Header cartProducts = {cartProducts} match = {match} windowWidth = {windowWidth}/>

    <div className ='product-page'>
        <h3>Biurka samochodowe | <b>{products[productID].name.slice(20)}</b></h3>
        
        <div className = 'main-container'>

        <Slider windowWidth = {windowWidth}/>

        <div className = 'product-content'>
            
        <div className = 'important-info'>
            <p className ='name'>{products[productID].name}</p>
            <p className ='title'>{products[productID].title}</p>

            <div className = 'check-me-out'>
                {
                    products[productID].addToCart ?
                    <div className = 'quantity'>
                    <p className = 'ilosc'>ilość</p>
                    <CartQuantity />                       
                    </div>
                    : null
                }

                
                <div className = 'price-container'>

                    <p className = 'price' style={products[productID].price2 ? {textDecoration: 'line-through'}: undefined}>{products[productID].price1}</p>
                    {
                        products[productID].price2 ? <p className = 'price'> {products[productID].price2} </p> : null
                    }

                </div>
          
            </div>

            {
            products[productID].addToCart ?
            <button id = 'cart-button-product' onClick = {sendToCart}> Dodaj do koszyka</button>
            : 
            null
           }
            

        </div>
        <p className ='sub-title'>Opis</p>
        <div>
            
            {
                products[productID].description.map(paragraph => (
                    <p key = {products[productID].id} className = 'paragraph'>
                        {paragraph}
                    </p>
                ))
            }

            <p className = 'paragraph' style = {{marginTop: "20px" , textDecoration: 'underline'}}>Czas realizacji:</p>
            <p className = 'paragraph' style = {{fontWeight: "600"}}>{products[productID].czasRealizacji.title ? products[productID].czasRealizacji.title: null} <br/> {products[productID].czasRealizacji.paragraf1}</p>
            <p className = 'paragraph' style = {{fontWeight: "600"}}>{products[productID].czasRealizacji.paragraf2}</p>

                 
        </div>



  
        </div>

        <div className = "product-tech">
            <ul>
                <p style = {{textDecoration: "underline"}}>Dane techniczne</p>
                {products[productID].tech.map(
                    el => <li key = {el} >{el}</li>
                )}
            </ul>
            <br/>
            <p className = 'bolded'>Wzór naszego biurka jest chroniony prawnie na terenie UE jako wzór przemysłowy nr 006359071-0001 zarejestrowany w Urzędzie Unii Europejskiej ds. własności intelektualnej (EUIPO).</p>
            <p>Prosimy przed zakupem zapoznać się z Kartą Produktu:</p>
            <div className = 'karta-produktu'>
            <a   href={Karta_produktu} target = "_blank" rel="noopener noreferrer" > <span class="far fa-file-pdf"></span> Karta produktu</a>
            </div>


        </div>

        </div>   
    

        <div className='lala'>
                    <div className = 'separate'></div>
        </div>
    </div>

    <div className = 'footer-container-wrap'>
    <div className = 'contact-form-footer'>
        <Kontakt para1 = 'Zależy nam, aby deska PLY STATION zapewniła Ci wygodę i komfort pracy. Jeśli masz jakiekolwiek pytania- skorzystaj z naszego formularza kontaktowego.'
                para2 = 'Chciałbyś złożyć zamówienie niestandardowe? Napisz do nas!'            
        />
        <ContactForm />
        <Footer match = {match} history = {history}/>
    </div>
    </div>
    </React.Fragment>

    )
               
                }

export default ProductPage
