import React from 'react'

import './kontakt.style.scss'

const Kontakt = ( {para1, para2} ) => (
    <section id = 'kontakt'>
        <h3>Kontakt</h3>
        <h2>Masz pytania?</h2>
        <p>
            {para1}
        </p>
        <p>
            {para2}
        </p>
    </section>
)

export default Kontakt
