import React from 'react'
import './menuButton.style.scss'

const MenuButton = ({ menuOpened, toggleMenu, windowWidth, cartProducts}) => {

    if(windowWidth >= 1100) {
        return null
    } else {
        return <button className = 'menu-button' onClick = {toggleMenu}>
                    {
                    menuOpened ? <span className="fas fa-times"></span>                  
                    : <span class="fas fa-bars"></span>
                    }
                </button>
    }
}

export default MenuButton