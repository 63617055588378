import React from 'react'

import './shopTop.style.scss'


const ShopTop = ( {windowWidth, shopimage} ) => (
    
    <section className='shop-top'>
        {
          
            <div className ='bg-container'>
                <div className ='centero'>
                    <div className = 'content'>
                        <h1>Auto jest twoim biurem?</h1>
                        <p>Deska PLY STATION - to wyjątkowy produkt, który powstał z potrzeby efektywnej pracy w samochodzie.</p>
                        <p>W kilka sekund deska założona na kierownicę zmienia się w ergonomiczne biurko, pozwalające na wygodną pracę na laptopie i innych urządzeniach mobilnych.
                        </p>
                    </div>

                    {
                        windowWidth > 1100 ?
                        <div className = 'img-shop-top'>
                        <img src = {shopimage} alt = 'Deska PLY STATION zapakowana do transportu'/>
                        </div>
                        : null
                    }
                  
                </div>

            </div>
        }
    </section>
    
)

export default ShopTop