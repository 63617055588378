import React from 'react'

import './cart.style.scss'


const Cart = ( {cartProducts, products, deleteFromCart, plusQuantity, minusQuantity} ) => (

    <div className = 'cart-page'>
        <h3>Koszyk</h3>
        <div className ='content-cart'>

        {
            cartProducts.length === 0 ?
            <div style = {{padding: '1rem 1.5rem'}}>
              <p className = 'cart-header'>Twój koszyk jest pusty.</p> 
            </div> :
            <div>
                  <table className = 'cart-table'>
                    <tr className = 'cart-table-row'>
                      <th>Produkt</th>
                      <th>Cena [zł]</th>
                      <th>Ilość</th>
                      <th>Cena łączna [zł]</th>
                      <th>Usuń</th>
                    </tr>


                    {
                  cartProducts.map(product => (
                      <tr className = 'cart-table-row'>
                        <td >
                          <div className = 'main-cart-info'>
                            <img src = {products[product[0]].photo} alt = 'zdjęcie produktu'></img>
                            <div>{products[product[0]].name.slice(20)}</div>
                          </div>
                        </td>

                        <td >
                          <div className = 'price-one'>{products[product[0]].price2 ? products[product[0]].price2.slice(0,-2): products[product[0]].price1.slice(0,-2)}</div>
                        </td>

                          <td >
                            <div className = 'cart-quantity'>
                            <div className = 'cart-button-quantity' onClick = { () => minusQuantity(products[product[0]].id)} >-</div>
                            <div >{product[1]}</div>
                            <div className = 'cart-button-quantity' onClick = { () => plusQuantity(products[product[0]].id)} >+</div>
                            </div>  
                          </td>   

                          <td>
                            <div className = 'price-one'>{products[product[0]].price2 ? parseInt(products[product[0]].price2)*parseInt(product[1]) : parseInt(products[product[0]].price1)*parseInt(product[1])}</div>
                          </td>

                          <td>
                            <div className = 'price-one' style = {{ cursor: 'pointer'}} 
                            onClick = {() => deleteFromCart(products[product[0]].id)}>X</div>   
                          </td>
                     </tr>
                  
                  ))
              }


                  </table>
                 
            </div>
        }
        </div>   
    </div>
    )
    

export default Cart
