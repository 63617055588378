  import React, {useState} from 'react';
  
  const CartQuantity = () => {

    const [quantity, setquantity] = useState(1)

    const add = () => {
        setquantity(prevquantity => prevquantity + 1);
    }
    
    const substract = () => {
        setquantity(prevquantity => (
            prevquantity === 1 ? 1 : prevquantity - 1
        ))
    }

return(
    < div className = 'btns'>
    <div>
        <button onClick = {substract}>-</button>
    </div>
    <div  id = 'quantityToCart'>{quantity}</div>
    <div>
        <button onClick = {add}>+</button>
    </div>
    </div>
    );
  

  };

  export default CartQuantity
   
   
   
   
