import React from 'react'

import Spinner from '../spinner/Spinner'

import './infoBox.styles.scss'



const FormInfoBox = ( {infoBox} ) => {

    return(
        <div className = 'info-box-checkout' >

            {
                infoBox === 'Loading' ?
                <Spinner /> :
                <p>{infoBox}</p>
            }


        </div>
    )
}

export default FormInfoBox
