import React, { Component } from 'react'

import Shop from '../components/shop/Shop'
import Kontakt from '../components/section6-kontakt/Kontakt'
import ContactForm from '../components/contactForm/ContactForm'
import Footer from '../components/footer/Footer'
import ShopTop from '../components/shopTop/ShopTop'
import ShopItem from '../components/shopItem/ShopItem'
import ProductPage from './ProductPage'

import Header from '../components/header/Header'


import './shopPage.style.scss'


class ShopPage extends Component {


componentDidMount() {
  window.scrollTo(0, 0)

}

  render() {

    
const gotoProduct = (e) => {
  this.props.history.push(`/sklep/produkt${e.target.id}`)
  window.scrollTo(0, 0)
}

    return (
        <React.Fragment>
          {/* <Modal /> */}
          <Header cartProducts = {this.props.cartProducts} match = {this.props.match} windowWidth = {this.props.windowWidth}/>
          <ShopTop windowWidth = {this.props.windowWidth} shopimage = {this.props.shopimage}/>
          <div className = 'shop-page'>
          <div className ='container'>
          <h3 style = {{padding: this.props.windowWidth >= 1100 ? '1.5rem 0rem' : '2rem 1.5rem'}} >Biurka samochodowe</h3>

          <div className='product-container'>
              {
                this.props.products.map(product => (
                  <div id = {product.id} onClick ={gotoProduct}>
                  <div className = 'img-container' id = {product.id}  >
                        <img src = {product.photo} alt = 'Widok produktu' id = {product.id} />
                        <div id = {product.id} className ='label' style = {labelStyles[product.label]}> {product.label}  </div> 
                    </div>
                    <p className ='name' id = {product.id}>{product.name}  </p>
                    <p className ='description' id = {product.id}>{product.title} </p>
                    <p id = {product.id} className = 'price' style={product.price2 ? {textDecoration: 'line-through'}: undefined}>{product.price1}</p>    
                    {product.price2 ? <p id = {product.id} className = 'price'> {product.price2} </p> : null}

                  </div>
                ))
              }
                    
            </div>

          <div className='lala'>
              <div className = 'separate'></div>
          </div>
          </div>
          </div>

          <div className = 'footer-container-wrap'>
    <div className = 'contact-form-footer'>
        <Kontakt para1 = 'Zależy nam, aby deska PLY STATION zapewniła Ci wygodę i komfort pracy. Jeśli masz jakiekolwiek pytania- skorzystaj z naszego formularza kontaktowego.'
                para2 = 'Chciałbyś złożyć zamówienie niestandardowe? Napisz do nas!'            
        />
        <ContactForm />
        <Footer match = {this.props.match} history = {this.props.match}/>
    </div>
    </div>
        </React.Fragment>
    )
  }
}

export default ShopPage


const labelStyles = {
  PROMOCJA: {
      background: "#E55D45",
      color: "#FFFFFF"
  },
  NOWOŚĆ: {
      background: "#15594D",
      color: "#FFFFFF"
  },
  WKRÓTCE: {
      background: "rgb(86, 89, 94)",
      color: "#FFFFFF"
  }
}