import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import './modal.style.scss'

const Cookie = ( {text} ) => {

const [modalActive, closeModal] = useState(true)

const changeModal = () => {
    closeModal(false)
}

    return (

        <>
        {   
            modalActive ? 
            <div className ='modal-cookie'>
            <span className = 'close-modal-cookie' onClick = {changeModal}>X</span>
            <p>Nasza strona używa plików Cookies. </p>

            <Link to="/cookies" style = {{cursor: "pointer" , color: "#15594D"}} >
                <p >Dowiedz się więcej.</p>
            </Link>    
            </div>
        : null

        }
        </>
          
    )
}

export default Cookie

