import React, {Component} from 'react'

import './section3.style.scss'


export class Section3 extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            id: 1,
            menu: "Komputer",
            text: "Postaw laptop na biurku i wygodnie napisz maila, przygotuj prezentację lub stwórz zestawienie w arkuszu kalkulacyjnym.",
            color1: 'rgba(40, 54, 51, 0.5)',
            color2: 'rgba(40, 54, 51, 1)'
        }
    }
      
    getMenu = (e) => {
        this.setState ({ menu: e.target.innerText })

        if(e.target.innerText === "Komputer") {
            this.setState({id: 1})
            this.setState({ text: "Postaw laptop na biurku i wygodnie napisz maila, przygotuj prezentację lub stwórz zestawienie w arkuszu kalkulacyjnym."})
        } else if (e.target.innerText === "Posiłek") {
            this.setState({id: 4})
            this.setState({text: "W czasie śniadania, lunchu lub kiedy tylko będziesz mieć ochotę - deska PLY STATION zamieni się w praktyczny stolik, na którym postawisz swój posiłek i napój."})
        } else if (e.target.innerText === "Smartfon") {
            this.setState({id: 3})
            this.setState({text: "Umieść smartfon w małym slocie i wygodnie przeprowadź wideokonferencję- sporządzając jednocześnie notatki albo wpisując spotkanie do kalendarza."})
        } else if (e.target.innerText === "Tablet") {
            this.setState({id: 2})
            this.setState({text: "Ustaw tablet w dużym slocie i przeglądaj wygodnie internet albo przygotuj się do spotkania z klientem."})
        }
    }

    toRight = (e) => {
        let nr = this.state.id    
        nr === 4 ? nr = 1 : nr = nr+1  
        this.setState ({ id: nr })

        if(nr  === 1) {
            this.setState({menu: "Komputer"})
            this.setState({ text: "Postaw laptop na biurku i wygodnie napisz maila, przygotuj prezentację lub stwórz zestawienie w arkuszu kalkulacyjnym."})
        } else if (nr === 4) {
            this.setState({menu: "Posiłek"})
            this.setState({text: "W czasie śniadania, lunchu lub kiedy tylko będziesz mieć ochotę - deska PLY STATION zamieni się w praktyczny stolik, na którym postawisz swój posiłek i napój."})
        } else if (nr  === 3) {
            this.setState({menu: "Smartfon"})
            this.setState({text: "Umieść smartfon w małym slocie i wygodnie przeprowadź wideokonferencję- sporządzając jednocześnie notatki albo wpisując spotkanie do kalendarza."})
        } else if (nr === 2) {
            this.setState({menu: "Tablet"})
            this.setState({text: "Ustaw tablet w dużym slocie i przeglądaj wygodnie internet albo przygotuj się do spotkania z klientem."})
        }

    }

    toLeft = (e) => {
        let nr = this.state.id    
        nr === 1 ? nr = 4 : nr = nr-1  
        this.setState ({ id: nr })

        if(nr  === 1) {
            this.setState({menu: "Komputer"})
            this.setState({ text: "Postaw laptop na biurku i wygodnie napisz maila, przygotuj prezentację lub stwórz zestawienie w arkuszu kalkulacyjnym."})
        } else if (nr === 4) {
            this.setState({menu: "Posiłek"})
            this.setState({text: "W czasie śniadania, lunchu lub kiedy tylko będziesz mieć ochotę - deska PLY STATION zamieni się w praktyczny stolik, na którym możesz postawić swój posiłek i napój."})
        } else if (nr === 3) {
            this.setState({menu: "Smartfon"})
            this.setState({text: "Umieść smartfon w małym slocie i wygodnie przeprowadź wideokonferencję - sporządzając jednocześnie notatki albo wpisując spotkanie do kalendarza."})
        } else if (nr  === 2) {
            this.setState({menu: "Tablet"})
            this.setState({text: "Ustaw tablet w dużym slocie i przeglądaj wygodnie internet albo przygotuj się do spotkania z klientem."})
        }

    }

    componentDidMount() {
        const track = document.querySelector('#main-photo-slider-home');
                let initialPosition = null;
                let moving = false;

                const gestureStart = (e) => {
                e.preventDefault()
                initialPosition = e.pageX;
                moving = true;};

                const gestureMove = (e) => {
                if (moving) {
                    const currentPosition = e.pageX;
                    const diff = currentPosition - initialPosition;
                    if (Math.abs(diff) > 5) {
                        gestureEnd(diff)             
                    }}};


                const gestureEnd = (diff) => {
                var w = window.innerWidth;
                moving = false;
                if(diff < w/100 ) {
                    this.toRight()
                }
                else if(diff > 0 ) {
                    this.toLeft()

            }   

                };

                if (window.PointerEvent) {
                track.addEventListener('pointerdown', gestureStart);
                track.addEventListener('pointermove', gestureMove);
                track.addEventListener('pointerup', gestureEnd);  
                } else {
                track.addEventListener('touchdown', gestureStart);
                track.addEventListener('touchmove', gestureMove);
                track.addEventListener('touchup', gestureEnd);  
                track.addEventListener('mousedown', gestureStart);
                track.addEventListener('mousemove', gestureMove);
                track.addEventListener('mouseup', gestureEnd);  
                }
    }


    


    render() {

        return (
            <div className ='bg-section3'>    
            <section id = 'section3'>
                <div className ='top'>
                    <h3>Jak to działa</h3>
                    <nav>
                        <ul>
                            <li onClick = {this.getMenu} className = {this.state.menu === "Komputer" ? "active-slider-menu" : null}>Komputer</li>
                            <li onClick = {this.getMenu} className = {this.state.menu === "Tablet" ? "active-slider-menu" : null}>Tablet</li>
                            <li onClick = {this.getMenu} className = {this.state.menu === "Smartfon" ? "active-slider-menu" : null}>Smartfon</li>
                            <li onClick = {this.getMenu} className = {this.state.menu === "Posiłek" ? "active-slider-menu" : null}>Posiłek</li>
                        </ul>                 
                    </nav>
                </div>
            
           
            <div className = 'section3-content'>

                <div className = 'left'>
                    <div>

                    <h2>{this.state.menu}</h2>
                    <p>
                        {this.state.text}
                    </p>
                        {
                        this.state.menu === "Tablet" ?
                        <p style = {{ paddingTop: "16px"}} >
                        Możesz także podłączyć klawiaturę bluetooth i pracować efektywnie jak na notebooku. 
                        </p>
                        : null
                        }
                    </div>

                    <div className ='btn-container'>
                    <button onClick = {this.toLeft} >
                            <span className="fas fa-chevron-left" ></span>
                        </button>
                        <button onClick = {this.toRight}>
                            <span className="fas fa-chevron-right"></span>            
                        </button>
                    </div>
                </div>

                <div className = 'img-container' id ='main-photo-slider-home'>           
                    <img src = {require(`../../assets/Komputer.JPG`)} alt ="Używanie deski PLY STATION na różnych urządzeniach" className = {this.state.menu === "Komputer" ? "img-on-top" : null}/>
                    <img src = {require(`../../assets/Tablet.JPG`)} alt ="Używanie deski PLY STATION na różnych urządzeniach" className = {this.state.menu === "Tablet" ? "img-on-top" : null}/>
                    <img src = {require(`../../assets/Smartfon.JPG`)} alt ="Używanie deski PLY STATION na różnych urządzeniach" className = {this.state.menu === "Smartfon" ? "img-on-top" : null}/>
                    <img src = {require(`../../assets/Posiłek.JPG`)} alt ="Używanie deski PLY STATION na różnych urządzeniach" className = {this.state.menu === "Posiłek" ? "img-on-top" : null}/>     
                </div>

                </div>

            </section>
            </div>
        )
    }
}

export default Section3
