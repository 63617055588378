import React, {useState} from 'react'
import './form.style.scss'
import FormInfoBox from './FormInfoBox'
import firebase from 'firebase';
import {Form, Field} from 'react-final-form'

require('firebase/functions');

const required = value => value ? undefined : 'To pole jest wymagane.'
const regex = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)

const validateEmail = value => regex.test(value) ? undefined : 'Podany adres email jest nieprawidłowy.' ;

const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)


const ContactForm = () => {
   
    const [infoBox, setInfoBox] = useState('');

      
    const sendMail = async values => {
        const sendMail = firebase.functions().httpsCallable('sendMail');
        sendMail(values).then(function(result) {

            if (result.data) {
                setInfoBox('Twoja wiadomość została wysłana. Odpiszemy najszybciej jak to możliwe.')
            } else {
                setInfoBox('Przepraszamy, coś poszło nie tak. Spróbuj ponownie za chwilę.')
            }

        })
    }
    
    const checkChaptcha = async values => {
        setInfoBox('Loading')
        const SITE_KEY = '6LdDYt8ZAAAAAI3uJwZrmTUnaKZzO9MiFyUTGZyd'
        await window.grecaptcha.ready(() => {
        window.grecaptcha
            .execute(SITE_KEY, { action: "captcha" })
            .then(function(token) {
            const checkChaptcha = firebase.functions().httpsCallable('checkCaptcha');
            checkChaptcha(token).then(function(result) {
                if (result.data) {
                    sendMail(values)
                } else {
                    setInfoBox('Przepraszamy, coś poszło nie tak. Spróbuj ponownie za chwilę.')
                }
            })
        });
        })
    }
    
   

        return (
    <div className = 'contact-form'>
        <Form onSubmit = {checkChaptcha} >
        {({ handleSubmit, values, submitting, form}) => <form onSubmit={(event) => {
        const promise = handleSubmit(event);
        promise && promise.then(() => {
            form.restart();
        })
        return promise;
        }} 
        id = "contact-form-id" > 

                <Field 
                    name = 'name' 
                    validate = {required} >
                    { ({input, meta}) => (
                        <div className = 'input-wrapper'>
                        <label>IMIĘ I NAZWISKO</label>
                        <input {...input} className = {meta.error && meta.touched ? 'input-error' : 'input-normal'}/>
                        {meta.error && meta.touched && <p>{meta.error}</p>}
                    </div>)}
                </Field>

                <Field 
                    name = 'email' 
                    validate={composeValidators(required, validateEmail)} 
                    >
                    { ({input, meta }) => (
                        <div className = 'input-wrapper'>
                        <label>EMAIL</label>
                        <input {...input }
                        className = {meta.error && meta.touched ? 'input-error' : 'input-normal'}/>
                        {meta.error && meta.touched && <p>{meta.error}</p>}
                    </div>)}
                </Field>

                <Field 
                    name = 'text' 
                    validate = {required} >
                    { ({input, meta }) => (
                    <div className = 'input-wrapper'>
                        <label>TREŚĆ WIADOMOŚCI</label>
                        <textarea {...input} className = {meta.error && meta.touched ? 'input-error' : 'input-normal'}/>
                        {meta.error && meta.touched && <p>{meta.error}</p>}
                    </div>)}
                </Field>

            <input className ='in-sub' type="submit" value="Wyślij" disabled = {infoBox === "Loading"}/>
            <FormInfoBox infoBox = {infoBox}/>

            <div className = 'captcha-info'>
          Nasza strona jest chroniona przez zabezpieczenie reCAPTCHA i Google. Obowiązują:
            <a href="https://policies.google.com/privacy" target="_blank"> Polityka Prywatności</a> oraz
            <a href="https://policies.google.com/terms" target="_blank"> Warunki korzystania.</a>
          </div>

            </form>}
            
            
           


          </Form>
         
              
        </div>
        )};

export default ContactForm

 
