import React, {useState} from 'react'

import './modal.style.scss'

const Modal = ( {text} ) => {

const [modalActive, closeModal] = useState(true)

const changeModal = () => {
    closeModal(false)
}

    return (
        <div className = 'modal-container' style = {!modalActive ? {visibility: 'hidden', opacity: '0'} : null}>
            <div className ='modal'>
                <span className = 'close-modal' onClick = {changeModal}>X</span>

                {
                    text.map(para => (
                    <p style = {{textAlign: 'center'}}>{para}</p> 
                    ))
                }
                
                <div className ='icons'>
                    <div>
                        <a href='https://www.facebook.com/PlyStationDesks' rel="noopener noreferrer" target="_blank"><span className="fab fa-facebook-square" style = {{fontSize: '40px'}}></span></a>
                        <a href='https://www.facebook.com/PlyStationDesks' rel="noopener noreferrer"target="_blank">facebook.com/PlyStationDesks</a>
                    </div>
                    <div>
                <a href='https://www.instagram.com/plystationdesks/' rel="noopener noreferrer" target="_blank"><span className="fab fa-instagram" style = {{fontSize: '40px'}}></span></a>    
                        <a href='https://www.instagram.com/plystationdesks/' target="_blank" rel="noopener noreferrer">instagram.com/plystationdesks</a>
                    </div>
                </div>
                <p style = {{textAlign: 'center'}}>Jeśli chcesz się z nami skontaktować napisz do nas e-mail:</p> 
                <p style = {{textAlign: 'center'}}>plystationdesks@gmail.com</p>
                <p style = {{textAlign: 'center'}}>Pozdrawiamy, Zespół PLY STATION</p>              
            </div>
        </div>
    )
}

export default Modal

