import React, {useState, useEffect} from 'react'
import Logo from '../assets/logo.svg'
import { Link } from 'react-router-dom'
import Spinner from '../components/spinner/Spinner'

import './thankYou.style.scss'


const ThankYou = () => {

    const [status, setStatus] = useState("Loading")
    const [error, setError] = useState("")

    useEffect(() => {
       
        const url = new URL(window.location);
        const clientSecret = url.searchParams.get('payment_intent_client_secret');
        const stripe = window.Stripe('pk_live_51Hkns5LONRdAmV3Nd95abO8rQ6KEjSMeIyJOJZVwVUqw8xL7XwCMjpap5QDRgvC2YFF6SwQXpRRZfGsHlqXtaIvC00x7PXkMZm');
        

        stripe.retrievePaymentIntent(clientSecret).then(function(response) {
        if (response.error) {
            setStatus('notok')
            setError(response.error)
        } else if (response.paymentIntent && response.paymentIntent.status === 'succeeded') {
            setStatus('ok')
        } else {
            setStatus('notok')
        }
        });
       
        
    }, [])

    return(

        <div className = 'loading-start' style = {{width: "100vw", height: "100vh" ,background: 'rgba(13, 65, 55, 0.1)'}} >


        {
            status === "Loading" ? <Spinner /> :
               <div className = 'thankYou-page'>

               {
                  status === "ok" ?
                      <div className = 'middle'>
              
                      <div className = 'content-center'>
                          <h2>Dziękujemy za zakup w naszym sklepie!</h2>
                          <p>Zakasujemy rękawy i zabieramy się za produkcję Twojej deski! :)</p>
                          <p>Deski produkujemy w małym warsztacie pod Warszawą- i stąd paczka wyruszy w drogę do Ciebie. Robimy je na zamówienie, dlatego czas realizacji może wynieść do 21 dni- ale zazwyczaj staramy się wysłać je o wiele wcześniej.</p>
                          <p>Jesteśmy pewni, że z biurkiem samochodowym praca w samochodzie, będzie o niebo wygodniejsza, a jeżeli kupiłeś ją jako prezent- sprawisz obdarowanej osobie dużo radości!</p>
                          <p>Na skrzynkę e-mail dostaniesz także wiadomość z potwierdzeniem transakcji.</p>
                          <p>Pozdrawiamy,</p>
                          <p>Zespół PLY STATION</p>
                      </div>

                      <div className = 'links'>
                          <Link to="/" className = 'logo-cont'>
                              <img className = 'logo' src={Logo} alt='Plystation logo' />
                          </Link> 

                          <Link to="/" className = 'powrot'>
                              <div ><span className="fas fa-arrow-left"></span>Powrót do sklepu</div>
                          </Link> 
                      </div>
                  
                  </div>
                  : <div className = 'middle'> 
                    <div className = 'content-center'> 
                          <h2>Przepraszamy :(</h2>
                          <h2>Twoja płatność nie została zrealizowana.</h2>
                          <p></p>
                          <p>Spróbuj ponownie za chwilę.</p>
                    </div>
                    <div className = 'links'>
                          <Link to="/" className = 'logo-cont'>
                              <img className = 'logo' src={Logo} alt='Plystation logo' />
                          </Link> 

                          <Link to="/" className = 'powrot'>
                              <div ><span className="fas fa-arrow-left"></span>Powrót do sklepu</div>
                          </Link> 
                      </div>

                  </div>
              }
              </div>
        }


    </div>
      
     
    )
}

export default ThankYou