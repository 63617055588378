import React, { useEffect} from 'react';

import './captcha.style.scss'

const Captcha = () => {

const SITE_KEY = '6LdDYt8ZAAAAAI3uJwZrmTUnaKZzO9MiFyUTGZyd'


  useEffect(() => {
    const script = document.createElement("script")
    script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`
    document.body.appendChild(script)
  }, [])
  
  return (
    <div
      className="g-recaptcha"
      data-sitekey= {SITE_KEY}
      data-size="invisible"
    >

    </div>
  )
}

export default Captcha

