import React from 'react'

import icon from '../../assets/cartIcon.png'

import './cartIcon.style.scss'


const cartIcon = ({cartProducts}) => (
    
    <div className = 'cart-icon'>
        <img src = {icon} alt = 'Ikona koszyka' />
        {
            cartProducts.length > 0 ?
            <div className = 'items-count'>
                {cartProducts.reduce((acc, curr) => acc+ curr[1], 0)}
            </div>
            :null  
        }
            
    </div>
)

export default cartIcon