import React from 'react'

import Golden from '../../assets/desktop/image9a.jpg'
import GoldenMobile from '../../assets/mobile/section4_mobile.png'
import Author from '../../assets/1_O Nas_do kółka.jpg'

import './section4.style.scss'


const Section4 = ( {windowWidth} ) => (
    <section id = 'section4'>
        <div className = 'section-wrapper'>

        {/* {
            windowWidth < 1300 ?
            : null
        } */}
            
        <div className = "img-container">
            <img src = {windowWidth > 800 ? Golden : GoldenMobile} alt = 'Złote logo Plystation'/>
            <div className = 'bs one'></div>
            <div className = 'bs two'></div>
            <div className = 'bs three'></div>
            <div className = 'bs four'></div>
        </div>

    <div className = 'content-container-wrap'>
        <div className = 'content-container'>
            <div className = 'content-main'>
                <h3 >O Nas</h3>
                <h2 className = 'tytul'>PLYSTATION</h2>
                <h4>Pomysł na wygodę</h4>
                <p>
                Pomysł na biurko samochodowe zrodził się kilka lat temu, gdy dużo pracowałem w samochodzie. 
                Brak komfortu pracy na komputerze i tablecie sprawił, że zacząłem szukać rozwiązania, które ułatwiłoby mi codzienną pracę w aucie.
                </p>
                <p>
                Wraz z przyjaciółmi dopracowaliśmy pomysł i tak właśnie powstało nasze biurko samochodowe. 
                Mamy nadzieję, że ułatwi codzienną pracę również Tobie!
                </p>
                <div className = 'content'>
                    <div className = 'img-container-author'>
                        <img className = 'author-photo' src = {Author} alt = 'Piotr Wiśniewski, założyciel PLYSTATION'/>
                    </div>
                    <div style = {{paddingLeft: '25px'}}>
                        <p style = {{
                            fonSize: '16px',
                            lineHeight: '32px',
                            color: '#283633',
                            fontWeight: '600',
                        }}>Piotr Wiśniewski</p>
                        <p style = {{
                            color: 'rgba(40, 54, 51, 0.8)', 
                            opacity: '0.8', 
                            fontSize: '16px',
                            lineHeight: '32px'}}>
                            Założyciel i pomysłodawca
                        </p>
                    </div>
                </div>
                </div>

            </div>
        </div>
     </div>

    

    </section>
)

export default Section4
