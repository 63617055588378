import React, {Component} from 'react'

import './slider.style.scss'

import pic1 from '../../assets/Komputer.JPG'
import pic2 from '../../assets/Tablet.JPG'
import pic3 from '../../assets/Posiłek.JPG'
import pic4 from '../../assets/Smartfon.JPG'



export class Slider extends Component {
    
    constructor(props) {
        super(props)
       
        this.state = {
            id: 1,
   
            images: [
                {
                    id: 1, 
                    url: pic1,
                    alt: 'Użytkowanie komputera z pomocą deski Ply Station'
                },

                {
                    id: 2, 
                    url: pic2,
                    alt: 'Użytkowanie tabeltu z pomocą deski Ply Station'
                },

                {
                    id: 3, 
                    url: pic3,
                    alt: 'Spożywanie posiłku na desce Ply Station'
                },

                {
                    id: 4, 
                    url: pic4,
                    alt: 'Użytkowanie telefonu z pomocą deski Ply Station'
                },
            ],

            currentImage: {
                    id: 1, 
                    url: pic1,
                    alt: 'Użytkowanie komputera z pomocą deski Ply Station'
            }
        }
    }

    changeCurrentImage = (e) => {
        this.setState({id: parseInt(e.target.id)})
    }

    changeIdRight = () => {
        this.setState((state) => ({
            id: this.state.id < this.state.images.length ? state.id + 1 : 1 
          }))


    }

    changeIdLeft = () => {
        this.setState((state) => ({
            id: this.state.id === 1 ? this.state.images.length : this.state.id - 1 
          }))

    }

    componentDidMount() {
        const track = document.querySelector('#main-photo-slider');

                let initialPosition = null;
                let moving = false;

                const gestureStart = (e) => {
                e.preventDefault()
                initialPosition = e.pageX;
                moving = true;};

                const gestureMove = (e) => {
                if (moving) {
                    const currentPosition = e.pageX;
                    const diff = currentPosition - initialPosition;
                    if (Math.abs(diff) > 5) {
                        gestureEnd(diff)             
                    }}};


                const gestureEnd = (diff) => {
                var w = window.innerWidth;
                moving = false;
                if(diff < w/100 ) {
                    this.changeIdRight()
                }
                else if(diff > 0 ) {
                    this.changeIdLeft()

            }   

                };

                if (window.PointerEvent) {
                track.addEventListener('pointerdown', gestureStart);
                track.addEventListener('pointermove', gestureMove);
                track.addEventListener('pointerup', gestureEnd);  
                } else {
                track.addEventListener('touchdown', gestureStart);
                track.addEventListener('touchmove', gestureMove);
                track.addEventListener('touchup', gestureEnd);  
                track.addEventListener('mousedown', gestureStart);
                track.addEventListener('mousemove', gestureMove);
                track.addEventListener('mouseup', gestureEnd);  
                }
    }

    
    render() {

        return (
            <div className = 'slider'>
                    
                <div className = 'main-photo' id = 'main-photo-slider'>
                       <img src = {pic1} alt = 'Użytkowanie komputera z pomocą deski Ply Station' className = {this.state.id === 1 ? "img-on-top": null} />
                       <img src = {pic2} alt = 'Użytkowanie tabletu z pomocą deski Ply Station' className = {this.state.id === 2 ? "img-on-top": null}/>
                       <img src = {pic3} alt = 'Spożywanie posiłku na desce Ply Station' className = {this.state.id === 3 ? "img-on-top": null}/>
                       <img src = {pic4} alt = 'Użytkowanie telefonu z pomocą deski Ply Station' className = {this.state.id === 4 ? "img-on-top": null}/>
                </div>
                
                <div className = 'thumbs' >
                    <div className='button-slider' onClick ={this.changeIdLeft}>
                        <span className="fas fa-chevron-left" ></span>
                    </div>
                   
                    {
                        this.state.images.map( image => (
                            <div className = 'slider-img-cont' key = {image.id} style = {image.id === this.state.id ? {border: '3px solid #15594D'} : undefined } >    
                                    <img src = {image.url} 
                                        alt = {image.alt} 
                                        id = {image.id}
                                        onClick = {this.changeCurrentImage} 
                                        />                              
                            </div>
                        ))
                    }
                    <div className='button-slider' onClick ={this.changeIdRight}>
                        <span className="fas fa-chevron-right"></span> 
                    </div>
                </div>
            </div>
        )
    }
}

export default Slider

