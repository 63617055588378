import React from 'react'
import Logo from '../assets/logo.svg'
import { Link } from 'react-router-dom'

import './cookiePage.style.scss'


const CookiePage = () => {


    return(

        <div className = 'thankYou-page' >

        <div className = 'middle'>
              
            <div className = 'links'>
                  <Link to="/" className = 'powrot'>
                      <div ><span className="fas fa-arrow-left"></span>Powrót do sklepu</div>
                  </Link> 

                  <Link to="/" className = 'logo-cont'>
                      <img className = 'logo' src={Logo} alt='Plystation logo' />
                  </Link>              
            </div>
            <div className = 'cookie-content'>
                <p>W celu realizacji twojego zamówienia nasza strona wykorzystuje pliki Cookies.</p>
                <p>Ciasteczka (ang. cookies) to niewielkie pliki, zapisywane i przechowywane na Twoim komputerze lokalnym lub innym urządzeniu podczas, 
                    gdy odwiedzasz różne strony WWW w sieci Internet.</p>
                <p>Więcej na temat plików Cookies dowiesz się klikając <a href = "https://pl.wikipedia.org/wiki/HTTP_cookie" target = "_blank">TUTAJ</a></p>
                <p>PLY STATION wykorzystuje twoje dane wyłącznie w celu realizacji usługi.</p>
                <p>W celu zapewnienia bezpieczeństwa w naszym sklepie, korzystamy z usług zewnętrznych, które wykorzystują pliki Cookies.</p>
                <p>Poniżej znajdziesz listę naszych partnerów, krótki opis oraz odnośniki do Polityki Prywatności i Warunków korzystania każdego partnera.</p>
                <ul>
                    <li>
                        <h3>Google i reCAPTCHA</h3>
                        <p>Wykorzystywane w celu wykrycia botów w naszym sklepie.</p>
                        <div className ='privacy-links'>
                            <a href = "https://policies.google.com/privacy" target = "_blank">POLITYKA PRYWATNOŚCI</a>
                            <a href = "https://policies.google.com/terms" target = "_blank">WARUNKI KORZYSTANIA</a>
                        </div>
                    </li>
                    <li>
                        <h3>Firebase</h3>
                        <p>Korzystamy z usługi Firebase/functions w celu zapewnienia bezpieczeństwa poprzez realizację niektórych funkcji po stronie niedostępnej dla użytkownika.</p>
                        <p>Korzystamy z usługi Firebase/firestore na wewnętrzne potrzeby obsługi serwisu. Twoje dane nie są przez nas w żaden sposób przechowywane.</p>
                        <div className ='privacy-links'>
                            <a href = "https://firebase.google.com/support/privacy" target = "_blank">POLITYKA PRYWATNOŚCI</a>
                            <a href = "https://firebase.google.com/terms" target = "_blank">WARUNKI KORZYSTANIA</a>
                        </div>
                    </li>
                    <li>
                        <h3>Stripe</h3>
                        <p>Stripe jest ogólnoświatową platformą do realizacji płatności.</p>
                        <p>Dzięki tej usłudze twoja płatność realizowana jest w sposób wygodny i bezpieczny.</p>
                        <div className ='privacy-links'>
                            <a href = "https://stripe.com/en-pl/privacy" target = "_blank">POLITYKA PRYWATNOŚCI</a>
                            <a href = "https://stripe.com/cookies-policy/legal" target = "_blank">POLITYKA COOKIES</a>
                        </div>
                    </li>
                </ul>
                <p>Robiąc zakupy w naszym sklepie oświadczasz, że zapoznałaś/eś się oraz akceptujesz powyżysze informacje.</p>

            </div>

          </div>

        </div>    
    )
}

export default CookiePage