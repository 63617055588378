import React from 'react'
import './section2.style.scss'


import Desktop from '../../assets/desktop/section2_desktop.png'
import Image1 from '../../assets/mobile/section2_image1_mobile.png'
import Image2 from '../../assets/mobile/section2_image2_mobile.png'
import Image3 from '../../assets/mobile/section2_image3_mobile.png'
import Image4 from '../../assets/mobile/section2_image4_mobile.png'
import Image5 from '../../assets/mobile/section2_image5_mobile.png'



const Section2 = ( {windowWidth} ) => (
    <section id='section2'>
        <h3>Biurka samochodowe</h3>
        <h2>Poznaj nasze biurko</h2>
        
        {
            windowWidth > 1300 ?
            <div className = 'image-wrapper'>
                <img className = 'big' src = {Desktop} alt ="Deska PLY STATION z opisem"/>
                <p className = 'para para-left para1'>
                    Specjalnie uformowane wycięcie i zaookrąglona krawędź -
                    zapewniają bezpieczny montaż na kierownicy i są przyjemne w dotyku.
                </p>
                <p className = "para para-left para2">
                    Duża płaska powierzchnia, pozwala na postawienie laptopa, położenie notesu lub wygodne zjedzenie posiłku.
                </p>
                <p className = 'para para-left para3'>
                    Zaokrąglona krawędź- zapewnia rozłożenie naprężeń na kierownicy i jest <br/> przyjemna w dotyku.
                </p>
                <p className = 'para para-left para4'>
                    Wykonane ze sklejki brzozowej-<br />trwałe i naturalne.
                </p>
                <p className = 'para para-right para5'>
                    Slot na smartfon.
                </p>
                <p className = 'para para-right para6'>
                    Slot na tablet.
                </p>
                <p className = 'para para-right para7'>
                    Logo PLY STATION <br />
                    bo jesteśmy dumni<br />z naszego produktu :)
                </p>
                <p className = 'para para-right para8'>
                    Wzór przemysłowy naszego <br /> biurka jest chroniony prawnie<br />na terytorium UE.
                </p>

            </div>
            :           
            <div>
                <img className = 'small' src = {Image1} alt = "Detal deski PLY STATION" />
                <p>
                    Specjalnie uformowane wycięcie i zaookrąglona krawędź -
                    zapewniają bezpieczny montaż na kierownicy i są przyjemne w dotyku.
                </p>
                <img className = 'small' src = {Image2}  alt = "Detal deski PLY STATION"/>
                <p>
                    Duża płaska powierzchnia, pozwala na postawienie laptopa, położenie notesu lub wygodne zjedzenie posiłku.
                </p>
                <img className = 'small' src = {Image3}  alt = "Detal deski PLY STATION"/>
                <p>
                    Sloty na smartfon i tablet
                </p>
                <img className = 'small' src = {Image4}  alt = "Detal deski PLY STATION"/>
                <p>
                    Wykonane ze sklejki brzozowej - trwałe i naturalne
                </p>
                <img className = 'small' src = {Image5}  alt = "Detal deski PLY STATION"/>
                <p>
                    Wzór przemysłowy naszego biurka jest chroniony prawnie na terytorium UE
                </p>
            </div>

        }    
    </section>
    
)

export default Section2
